import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 12,
    title: "React Native Animated",
    desc: "Animazioni Fluide: Guida dettagliata.",
    img: "/blog-image/rAnimated.png",
    page: "blog/reactAnimated",
    data: "6 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Le animazioni sono un elemento fondamentale per creare un'esperienza utente coinvolgente nelle app mobili.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image text-center">
                                    <img
                                        className="rotate-img"
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Le animazioni sono un elemento
                                        fondamentale per creare un'esperienza
                                        utente coinvolgente nelle app mobili.
                                        React Native, con la sua libreria
                                        Animated, offre un potente strumento per
                                        creare animazioni fluide e sofisticate.
                                        In questo articolo, esploreremo l'uso
                                        della libreria Animated per creare
                                        animazioni coinvolgenti nelle app React
                                        Native.
                                    </p>
                                    <p>
                                        La libreria Animated di React Native
                                        fornisce un sistema di animazione
                                        performante e flessibile utilizzando un
                                        approccio basato su nodi,dove gli
                                        oggetti "Animated" rappresentano valori
                                        animabili come opacità, posizione e
                                        scala. Questi oggetti vengono manipolati
                                        e animati in modo efficiente, senza
                                        causare un alto carico sulla UI.
                                    </p>
                                    <h3>Creazione di Animazioni</h3>

                                    <MyCoolCodeBlock
                                        code={`
    import { Animated } from 'react-native';

    const opacity = new Animated.Value(0);

    Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
    }).start();

                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <p className="pt-3">
                                        Nell'esempio sopra, utilizziamo
                                        `Animated.timing` per creare
                                        un'animazione di tipo temporizzato che
                                        modifica l'opacità dall'0 al 1 in 500
                                        millisecondi.
                                    </p>
                                    <h5>Combinazione di Animazioni</h5>
                                    <p>
                                        Animated consente anche di combinare
                                        diverse animazioni per creare effetti
                                        complessi. Ad esempio, possiamo animare
                                        contemporaneamente la posizione e la
                                        scala di un componente:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    const position = new Animated.ValueXY({ x: 0, y: 0 });
    const scale = new Animated.Value(1);

    Animated.parallel([
        Animated.timing(position, { toValue: { x: 100, y: 100 }, duration: 500 }),
        Animated.timing(scale, { toValue: 2, duration: 500 }),
    ]).start();
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="pt-3">
                                        Nell'esempio sopra, utilizziamo
                                        `Animated.parallel` per eseguire le due
                                        animazioni contemporaneamente.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h5>Interpolazione</h5>
                                    <p>
                                        L'interpolazione è un concetto chiave in
                                        Animated. Consente di mappare un
                                        intervallo di valori di input a un
                                        intervallo di valori di output. Questo è
                                        utile per creare animazioni più
                                        complesse. Ad esempio, possiamo creare
                                        un'animazione in cui l'opacità aumenta
                                        mentre il componente si sposta verso il
                                        basso:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    const offsetY = new Animated.Value(0);
    const opacity = offsetY.interpolate({
        inputRange: [0, 100],
        outputRange: [1, 0],
        extrapolate: 'clamp',
    });

    Animated.timing(offsetY, { toValue: 100, duration: 500 }).start();
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p className="pt-3">
                                        Nell'esempio sopra, stiamo utilizzando
                                        l'interpolazione per mappare il valore
                                        di `offsetY` da 0 a 100 nell'intervallo
                                        di opacità da 1 a 0.
                                    </p>

                                    <h5>Gestione degli Eventi</h5>
                                    <p>
                                        Animated offre la possibilità di
                                        ascoltare eventi durante le animazioni.
                                        Possiamo utilizzare questo per
                                        sincronizzare altre azioni con lo stato
                                        dell'animazione. Ad esempio, possiamo
                                        eseguire una determinata azione quando
                                        un'animazione è completata:
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`
    const opacity = new Animated.Value(0);

    const fadeIn = Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
    });

    fadeIn.start(() => {
        console.log('Animation complete');
    });
                                        
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>Utilizzo di Easing</h5>
                                    <p>
                                        Gli easing sono funzioni matematiche che
                                        definiscono come un'animazione dovrebbe
                                        procedere nel tempo. Animated supporta
                                        una varietà di easing predefiniti, come
                                        `Easing.linear`, `Easing.ease`,
                                        `Easing.easeIn`, ecc. Possiamo
                                        utilizzare l'opzione `easing` per
                                        applicare un easing specifico a
                                        un'animazione:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
    Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
        easing: Easing.easeOut,
    }).start();
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />

                                    <p className="pt-3">
                                        La libreria Animated di React Native
                                        costituisce un prezioso strumento per
                                        dare dinamismo e rendere coinvolgenti le
                                        applicazioni, sviluppando animazioni di
                                        notevole impatto, effetti di transizione
                                        e composizioni di trasformazioni
                                        altamente complesse. Vogliamo aiutarti a
                                        far brillare le tue app con animazioni
                                        straordinarie - se desideri scoprire il
                                        pieno potenziale di Animated, perché non
                                        parli con noi? Siamo qui per darti una
                                        consulenza specializzata su React e
                                        ascoltare tutto ciò che hai in mente per
                                        i tuoi progetti. Contattaci oggi stesso!
                                    </p>

                                    <br />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
